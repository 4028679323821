<template>
        <div class="create-modal" v-click-outside="onClickOutside">
            <h3 class="create-modal--title">
                Create new project
            </h3>
            <form class="create-modal__form">
                <button href="" class="close_X" @click="$emit('close')">
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1.33301 14.6667L7.99967 7.99999M14.6663 1.33333L7.99967 7.99999M7.99967 7.99999L14.6663 14.6667M7.99967 7.99999L1.33301 1.33333" stroke="#E7E7E8" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                </button>
                <div class="create-modal__item">
                    <p class="create-modal__name">
                        <label for="">
                            Project name
                        </label>
                        <input type="text" v-model="title">
                    </p>
                    <div class="create-modal__color" @click="showSelectColorModal = !showSelectColorModal">
                        <p class="create-modal__color-circle"></p>
                        <svg width="8" height="5" viewBox="0 0 8 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3.59749 4.33904C3.81979 4.55354 4.18021 4.55355 4.40251 4.33904L7.58213 1.27092C7.94073 0.924897 7.68676 0.333252 7.17962 0.333252H0.820377C0.313243 0.333252 0.0592681 0.924896 0.417866 1.27092L3.59749 4.33904Z" fill="black"/>
                        </svg>
                        <SelectColorModal :updateColor="updateColor" v-model="color"  v-if="showSelectColorModal"  @close="showSelectColorModal = false" />
                    </div>
                </div>
                <p class="create-modal__description">
                    <label for="">Description</label>
                    <textarea name="" id="" v-model="description" cols="30" rows="10"></textarea>
                </p>
                <p v-if="showTagSelect" class="create-modal__tags">
                    <label for="">Tags</label>
                    <TagSelectModal />
                </p>
                <p class="create-modal__checkboxes">
                    <input class="billable" type="checkbox" name="" v-model="billable" id="billable"><label for="billable" class="billable__label">Billable</label>
                    <input class="public" type="checkbox" name="" v-model="isPublic" id="public"><label for="public" class="public__label">Public</label>
                </p>
                <p class="create-modal__tags">
                    <label for="">Client</label>
                    <v-selectize v-model="selectedC" :options="clientOptions"/>
                </p>
                <div v-if="showDeadline" class="create-modal__item create-modal__dead">
                    <p class="create-modal__name">
                        <label for="">
                            Deadline
                        </label>
                    </p>
                    <p class="create-modal__dead-input">
                        <svg width="20" height="22" viewBox="0 0 20 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M6 1V5M14 1V5M19 9H1M5 13H6M9.5 13H10.5M14 13H15M5 17H10.5M14 17H15M3 21H17C18.0464 21 19 20.0042 19 19V5C19 3.99585 18.1517 3 17.1053 3H3C1.95357 3 1 3.99585 1 5V19C1 20.0042 1.95357 21 3 21Z" stroke="#BABCBF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                        <input class="create-modal__dead-date" v-model="deadlineDate" type="date" name="" id="">
                        <input class="create-modal__dead-time" v-model="deadlineTime" type="time" name="" id="">
                    </p>
                </div>
                <div class="buttons">
                    <button @click="saveProject" class="create-modal__create">
                        Create
                    </button>
                    <button @click="$emit('close')">
                        Cancel
                    </button>
                </div>
            </form>
        </div>
</template>

<script>
    import vClickOutside from 'v-click-outside'
    import 'selectize/dist/css/selectize.default.css' // This is required, we use the same css as selectize.js
    import VSelectize from '@isneezy/vue-selectize'
    import TagSelectModal from '@/components/Modal/TagSelectModal'
    import SelectColorModal from '@/components/Modal/SelectColorModal'
    import { useClientStore } from '@/store/ClientStore'
    import { useProjectStore } from '@/store/ProjectStore'

    export default {
        props: ['project'],
        data() {
            const clientStore = useClientStore()
            const projectStore = useProjectStore()
            return {
                title: this.project ? this.project.t : '',
                color: this.project ? this.project.cs : 'E058B2',
                description: this.project ? this.project.n : '',
                billable: this.project ? this.project.ib : false,
                isPublic: this.project ? this.project.ip : false,
                showDeadline: false,
                showTagSelect: false,
                selectedT: 'All tags',
                selectedC: {label:'', value:''},
                showSelectColorModal: false,
                clientOptions: [],
                clientStore: clientStore,
                projectStore: projectStore
            }
        },
        directives: {
            clickOutside: vClickOutside.directive
        },
        created(){
            this.clientStore.listing().then(() => {
                this.clients = this.clientStore.clients//.forEach(item => {item.label = item.i, item.value = item.uk})
                this.clientOptions.push({label: 'All Clients', value: ''})
                if( this.clients.length > 0 ) this.selectedC = this.clients[0]
                for(let i=0; i<this.clients.length; i++){
                    this.clientOptions.push({label: this.clients[i]['t'], value: this.clients[i]['uk']})
                }
            })
        },
        methods: {
            updateColor(cs){
                this.color = cs
            },
            saveProject(){
                let newProject = {
                        t: this.title,
                        ip: this.isPublic ? 1 : 0,
                        n: this.description,
                        idb: this.billable ? 1 : 0,
                        cs: this.color,
                        cuk: this.selectedC.value
                    }
                this.projectStore.insert(newProject)
                this.$emit('close')
            },
            onClickOutside () {
                this.$emit('close')
            }
        },
        components: {
            VSelectize, TagSelectModal, SelectColorModal
        }
    };
</script>

<style scoped>
    .create-modal{
        width: 549px;
        height: 728px;
        background-color: #fff;
        position: fixed;
        top: 5%;
        padding: 20px;
        color: #222222;
        box-sizing: border-box;
        margin-bottom: 50px;
        z-index: 30;
        right: calc( 50vw - 269px);
        margin-left: -273px;
    }

    .create-modal--title{
        font-size: 20px;
        font-weight: 600;
        line-height: 24px;
        text-align: left;
        margin: 0;
        padding-bottom: 25px;
        border-bottom: 2px solid #F2F3F6;
    }


    .create-modal__name{
        font-size: 14px;
        font-weight: 600;
        line-height: 17px;
        text-align: left;
        display: flex;
        flex-direction: column;
        flex-grow: 1;
    }

    .create-modal__item{
        display: flex;
        flex-direction: row;
    }

    .create-modal__tags select,
    .create-modal__name input{
        margin-top: 5px;
        border-radius: 30px;
        max-width: 449px;
        height: 42px;
        border: 2px solid #F2F3F6;
        font-family: Montserrat;
        outline: none;
        padding: 0 15px;
    }

    .create-modal__tags select{
        width: 100%;
        max-width: 100%;
    }


    .create-modal__color{
        align-self: flex-end;
        margin-bottom: 25px;
        cursor: pointer;
        margin-right: 10px;
        display: flex;
        flex-direction: row;
        align-items: flex-end;
        margin-left: 10px;
    }

    .create-modal__color svg{
        padding-bottom: 8px;
        padding-left: 7px;
    }

    .create-modal__tags,
    .create-modal__description{
        display: flex;
        flex-direction: column;
        box-sizing: border-box;
    }

    .create-modal__tags label,
    .create-modal__description label{
        font-size: 14px;
        font-weight: 600;
        line-height: 17px;
        padding-bottom: 5px;
    }

    .create-modal__description textarea{
        width: 100%;
        height:75px;
        border: 2px solid #F2F3F6;
        border-radius: 4px;
        outline: none;
        box-sizing: border-box;
    }

    .create-modal__tags select{
        font-weight: 600;
        appearance: none;
        -moz-appearance: none;
    }

    .create-modal__tags{
            position: relative;
    }
    

    .create-modal__tags > select::-ms-expand{
        display: none;
    }

    .create-modal__tags label{
        position: relative;
    }

    .create-modal__tags label:after{
        content: '';
        position: absolute;
        width: 7px;
        height: 7px;
        background-image: url('../../assets/img/Rectangle.svg');
        background-repeat: no-repeat;
        background-position: center;
        bottom: -27px;
        right: 25px;
        cursor: pointer;
    }

    .create-modal__checkboxes{
        display: flex;
        flex-direction: column;
    }

    .create-modal__checkboxes input{
        display: none;
    }

    .create-modal__checkboxes label{
        position: relative;
        width: 36px;
        height: 20px;
        margin: 15px 0 0 0;
        padding-left: 50px;
        font-size: 14px;
        font-weight: 600;
        line-height: 17px;
    }

    .create-modal__checkboxes label:after{
        position: absolute;
        content: '';
        width: 36px;
        height: 20px;
        background-image: url('../../assets/img/off.png');
        top: 0;
        left: 0;
    }

    .public:checked ~ .public__label:after{
        background-image: url('../../assets/img/on.png');
    }

    .billable:checked ~ .billable__label:after{
        background-image: url('../../assets/img/on.png');
    }

    .create-modal__dead{
        display: flex;
        flex-direction: column;
    }

    .create-modal__dead-input{
        display: flex;
        flex-direction: row;
        align-items: center;
        margin: 0;
        padding-bottom: 20px;
        border-bottom: 2px solid #F2F3F6;
    }

    .create-modal__dead-date{
        margin-left: 10px;
        border: 2px solid #F2F3F6;
        box-sizing: border-box;
        border-radius: 30px;
        width: 150px;
        height: 42px;
        padding: 0 10px;
        outline: none;
        font-family: Montserrat;
        color: #222222;
    }

    .create-modal__dead-time{
        margin-left: 10px;
        border: 2px solid #F2F3F6;
        box-sizing: border-box;
        border-radius: 30px;
        width: 110px;
        padding: 0 10px;
        outline: none;
        height: 42px;
        font-family: Montserrat;
        color: #222222;
    }

    button{
        border: none;
        background: none;
        cursor: pointer;
    }

    .buttons{
        display: flex;
        flex-direction: row;
    }

    .buttons button{
        margin-right: 10px;
        margin-top: 10px;
        width: 107px;
        height: 42px;
        font-family: 'Montserrat', sans-serif;
        background: #9CEDFF1A;
        border: 2px solid #9CEDFF;
        height: 42px;
        border-radius:30px;
        font-weight: 600;
        font-size: 16px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }

    .buttons .create-modal__create{
        background-color: #9CEDFF;
    }

    @media(max-width:800px){
        .bg{
            left: 0;
        }
    }

    
    @media(max-width:600px){
        .create-modal{
            width: 100vw;
            margin-left: -75px;
            right: 0;
            box-sizing: border-box;
        }
    }

    .close_X{
        position: absolute;
        right: 19px;
        top: 23px;
    }

    .create-modal__color-circle{
        width: 20px;
        height: 20px;
        margin: 0;
        padding: 0;
        border-radius: 50%;
        background-color: #E058B2;;
    }


</style>