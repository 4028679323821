<template>
    <div class="select-modal" v-click-outside="onClickOutside">
        <input type="text" placeholder="Find projects or client" v-model="search">
        <div class="select-modal__wrp">
            <div class="select-modal__item workspace">
                <div class="item__title">
                    <p class="item__category ">Workspace</p>
                    <p href="" class="item__quantity" @click="showWorkspace = !showWorkspace"> 
                        <span>
                            Current Workspace
                        </span>
                        <svg v-if="!showWorkspace" width="9" height="5" viewBox="0 0 9 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M1 1L4.5 4L8 1" stroke="#606569" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                        <svg v-else width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M1.5 4.75L5 1.25L8.5 4.75" stroke="#606569" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                    </p>
                </div>
                <div v-if="showWorkspace">
                    <div class="category-item" v-for="item in workspaceStore.workspaces" :key="item.uk" >
                        <div class="category-item__title">
                            <p class="category-item__name" @click="changeWorkplace(item)">
                                {{item.t}}
                            </p>
                            <p class="workspace-active" v-if="item.uk == workspaceStore.currentWorkspace.uk">
                                <span>Active</span>
                                <svg width="14" height="10" viewBox="0 0 14 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M1.375 5.34091L5.24219 8.75L12.625 1.25" stroke="#37BB5C" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="select-modal__item">
                <div class="item__title">
                    <p class="item__category clickable-task" @click="showFavTask = !showFavTask">Favorites</p>
                    <p href="" class="item__quantity" @click="showFavTask = !showFavTask"> 
                        <span>
                            {{ taskStore.tasksAll.favs.count }} Projects
                        </span>
                        <svg v-if="showFavTask" width="9" height="5" viewBox="0 0 9 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M1 1L4.5 4L8 1" stroke="#606569" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                        <svg v-else width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M1.5 4.75L5 1.25L8.5 4.75" stroke="#606569" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                    </p>
                    <svg class="item__quater" width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M9.59243 1.16675H11.5369C12.2528 1.16675 12.8332 1.74712 12.8332 2.46304V4.40749M4.40724 1.16675H2.4628C1.74688 1.16675 1.1665 1.74712 1.1665 2.46304V4.40749M4.40724 12.8334H2.4628C1.74688 12.8334 1.1665 12.253 1.1665 11.5371V9.59267M9.59243 12.8334H11.5369C12.2528 12.8334 12.8332 12.253 12.8332 11.5371V9.59267" stroke="#606569" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                </div>
                <div v-if="showFavTask">
                    <div class="category-item" v-for="item in taskStore.tasksAll.favs.projects" :key="item.puk" >
                        <div class="category-item__title">
                            <p class="category-item__name clickable-task" @click="openTasks(item)">
                                {{item.title}}
                            </p>
                            <p href="" class="item__quantity" @click="openTasks(item)"> 
                                <span>
                                    {{ item.count }} Tasks
                                </span>
                                <svg v-if="item.isOpenTasks" width="9" height="5" viewBox="0 0 9 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M1 1L4.5 4L8 1" stroke="#606569" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                                <svg v-else width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M1.5 4.75L5 1.25L8.5 4.75" stroke="#606569" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                            </p>
                            <p @click="changeProjectFav(item)">
                                <svg v-if="item.isFav" class="category-item__star" width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M6.80871 1.18687C7.1173 0.657058 7.8827 0.657057 8.19129 1.18687L9.94924 4.20508C10.0623 4.39916 10.2517 4.53678 10.4712 4.58432L13.8849 5.32354C14.4842 5.45331 14.7207 6.18125 14.3122 6.63846L11.9849 9.24304C11.8353 9.41052 11.7629 9.6332 11.7855 9.85666L12.1374 13.3317C12.1992 13.9417 11.5799 14.3916 11.0189 14.1444L7.8226 12.7359C7.61707 12.6453 7.38293 12.6453 7.1774 12.7359L3.98114 14.1444C3.42007 14.3916 2.80084 13.9417 2.8626 13.3317L3.21445 9.85666C3.23708 9.6332 3.16473 9.41052 3.01508 9.24304L0.687827 6.63846C0.279304 6.18125 0.515825 5.45331 1.11507 5.32354L4.52879 4.58432C4.7483 4.53678 4.93772 4.39916 5.05076 4.20508L6.80871 1.18687Z" fill="#FFD351"/>
                                </svg>
                                <svg v-else class="category-item__empty-star" width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg" >
                                    <path d="M6.73383 11.9479L7.09675 12.7714L6.73383 11.9479L3.93243 13.1823C3.90861 13.1928 3.89285 13.1931 3.88141 13.1917C3.8673 13.19 3.85009 13.1839 3.83333 13.1717C3.81657 13.1596 3.8055 13.1451 3.79947 13.1322C3.79458 13.1217 3.78999 13.1067 3.79262 13.0808L4.101 10.035C4.15473 9.50429 3.9829 8.97543 3.62748 8.57766L1.58774 6.29485C1.5704 6.27544 1.56524 6.26054 1.56306 6.24922C1.56037 6.23525 1.5608 6.21702 1.5672 6.19732C1.5736 6.17762 1.58397 6.16261 1.59436 6.15289C1.60278 6.14501 1.6157 6.13599 1.64114 6.13048L4.63313 5.48258C5.15447 5.36969 5.60435 5.04283 5.87282 4.58189L7.41359 1.93656C7.42669 1.91407 7.43926 1.90456 7.44935 1.89899C7.46181 1.89211 7.47929 1.88689 7.5 1.88689C7.52071 1.88689 7.53819 1.89211 7.55065 1.89899C7.56074 1.90456 7.57331 1.91406 7.58641 1.93656L9.12718 4.58189C9.39565 5.04283 9.84553 5.36969 10.3669 5.48258L13.3589 6.13048C13.3843 6.13599 13.3972 6.14501 13.4056 6.15289C13.416 6.16261 13.4264 6.17762 13.4328 6.19732C13.4392 6.21702 13.4396 6.23525 13.4369 6.24922C13.4348 6.26054 13.4296 6.27544 13.4123 6.29485L11.3725 8.57766C11.0171 8.97543 10.8453 9.50429 10.899 10.035L11.2074 13.0808C11.21 13.1067 11.2054 13.1217 11.2005 13.1322C11.1945 13.1451 11.1834 13.1596 11.1667 13.1717C11.1499 13.1839 11.1327 13.19 11.1186 13.1917C11.1071 13.1931 11.0914 13.1928 11.0676 13.1823L8.26617 11.9479C7.77804 11.7328 7.22196 11.7328 6.73383 11.9479Z" stroke="#FFD351" stroke-width="1.8"/>
                                </svg>
                            </p>
                        </div>
                        <ul class="category-item__list" v-if="item.isOpenTasks">
                            <li v-for="it in item.tasks" :key="it.uk">
                                <span class="clickable-task" @click="selectTask(it)">{{ it.t }}</span>
                                <svg v-if="it.tf == 1" class="category-item__star" @click="changeTaskFav(it)" width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M6.80871 1.18687C7.1173 0.657058 7.8827 0.657057 8.19129 1.18687L9.94924 4.20508C10.0623 4.39916 10.2517 4.53678 10.4712 4.58432L13.8849 5.32354C14.4842 5.45331 14.7207 6.18125 14.3122 6.63846L11.9849 9.24304C11.8353 9.41052 11.7629 9.6332 11.7855 9.85666L12.1374 13.3317C12.1992 13.9417 11.5799 14.3916 11.0189 14.1444L7.8226 12.7359C7.61707 12.6453 7.38293 12.6453 7.1774 12.7359L3.98114 14.1444C3.42007 14.3916 2.80084 13.9417 2.8626 13.3317L3.21445 9.85666C3.23708 9.6332 3.16473 9.41052 3.01508 9.24304L0.687827 6.63846C0.279304 6.18125 0.515825 5.45331 1.11507 5.32354L4.52879 4.58432C4.7483 4.53678 4.93772 4.39916 5.05076 4.20508L6.80871 1.18687Z" fill="#FFD351"/>
                                </svg>
                                <svg v-else class="category-item__empty-star" @click="changeTaskFav(it)" width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg" >
                                    <path d="M6.73383 11.9479L7.09675 12.7714L6.73383 11.9479L3.93243 13.1823C3.90861 13.1928 3.89285 13.1931 3.88141 13.1917C3.8673 13.19 3.85009 13.1839 3.83333 13.1717C3.81657 13.1596 3.8055 13.1451 3.79947 13.1322C3.79458 13.1217 3.78999 13.1067 3.79262 13.0808L4.101 10.035C4.15473 9.50429 3.9829 8.97543 3.62748 8.57766L1.58774 6.29485C1.5704 6.27544 1.56524 6.26054 1.56306 6.24922C1.56037 6.23525 1.5608 6.21702 1.5672 6.19732C1.5736 6.17762 1.58397 6.16261 1.59436 6.15289C1.60278 6.14501 1.6157 6.13599 1.64114 6.13048L4.63313 5.48258C5.15447 5.36969 5.60435 5.04283 5.87282 4.58189L7.41359 1.93656C7.42669 1.91407 7.43926 1.90456 7.44935 1.89899C7.46181 1.89211 7.47929 1.88689 7.5 1.88689C7.52071 1.88689 7.53819 1.89211 7.55065 1.89899C7.56074 1.90456 7.57331 1.91406 7.58641 1.93656L9.12718 4.58189C9.39565 5.04283 9.84553 5.36969 10.3669 5.48258L13.3589 6.13048C13.3843 6.13599 13.3972 6.14501 13.4056 6.15289C13.416 6.16261 13.4264 6.17762 13.4328 6.19732C13.4392 6.21702 13.4396 6.23525 13.4369 6.24922C13.4348 6.26054 13.4296 6.27544 13.4123 6.29485L11.3725 8.57766C11.0171 8.97543 10.8453 9.50429 10.899 10.035L11.2074 13.0808C11.21 13.1067 11.2054 13.1217 11.2005 13.1322C11.1945 13.1451 11.1834 13.1596 11.1667 13.1717C11.1499 13.1839 11.1327 13.19 11.1186 13.1917C11.1071 13.1931 11.0914 13.1928 11.0676 13.1823L8.26617 11.9479C7.77804 11.7328 7.22196 11.7328 6.73383 11.9479Z" stroke="#FFD351" stroke-width="1.8"/>
                                </svg>
                            </li>
                        </ul>
                        <button class="create-task-btn" v-if="item.isOpenTasks && showAddTask" @click="item.isCreateTasks = !item.isCreateTasks">
                            <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M13.7668 8.49998C13.7668 11.6848 11.185 14.2666 8.00016 14.2666V16.0666C12.1791 16.0666 15.5668 12.6789 15.5668 8.49998H13.7668ZM8.00016 14.2666C4.81532 14.2666 2.2335 11.6848 2.2335 8.49998H0.433496C0.433496 12.6789 3.82121 16.0666 8.00016 16.0666V14.2666ZM2.2335 8.49998C2.2335 5.31514 4.81532 2.73331 8.00016 2.73331V0.933313C3.82121 0.933313 0.433496 4.32103 0.433496 8.49998H2.2335ZM8.00016 2.73331C11.185 2.73331 13.7668 5.31514 13.7668 8.49998H15.5668C15.5668 4.32103 12.1791 0.933313 8.00016 0.933313V2.73331Z" fill="#58C7E0"/>
                                <path d="M7.10016 11.1666C7.10016 11.6637 7.50311 12.0666 8.00016 12.0666C8.49722 12.0666 8.90016 11.6637 8.90016 11.1666H7.10016ZM8.90016 5.83331C8.90016 5.33626 8.49722 4.93331 8.00016 4.93331C7.50311 4.93331 7.10016 5.33626 7.10016 5.83331H8.90016ZM10.6668 9.39998C11.1639 9.39998 11.5668 8.99704 11.5668 8.49998C11.5668 8.00292 11.1639 7.59998 10.6668 7.59998V9.39998ZM5.3335 7.59998C4.83644 7.59998 4.4335 8.00292 4.4335 8.49998C4.4335 8.99704 4.83644 9.39998 5.3335 9.39998V7.59998ZM8.90016 11.1666V8.49998H7.10016V11.1666H8.90016ZM8.90016 8.49998V5.83331H7.10016V8.49998H8.90016ZM8.00016 9.39998H10.6668V7.59998H8.00016V9.39998ZM8.00016 7.59998H5.3335V9.39998H8.00016V7.59998Z" fill="#58C7E0"/>
                            </svg>
                            <span> Create new task </span>
                        </button> 
                        <AddTaskModal :key="item.id" v-if="item.isCreateTasks" @close="item.isCreateTasks = false"/>
                        <div class="bg" :key="item.id" v-if="item.isCreateTasks" @close="item.isCreateTasks = false"></div>
                    </div>
                </div>
            </div>




            <div class="select-modal__item" v-for="client in taskStore.tasksAll.clients" :key="client.cuk">
                <div class="item__title">
                    <p class="item__category clickable-task" @click="openTasks(client)">{{ client.title }}</p>
                    <p href="" class="item__quantity" @click="openTasks(client)"> 
                        <span>
                            {{ client.count }} Projects
                        </span>
                        <svg v-if="client.isOpenTasks" width="9" height="5" viewBox="0 0 9 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M1 1L4.5 4L8 1" stroke="#606569" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                        <svg v-else width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M1.5 4.75L5 1.25L8.5 4.75" stroke="#606569" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                    </p>
                    <svg class="item__quater" width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M9.59243 1.16675H11.5369C12.2528 1.16675 12.8332 1.74712 12.8332 2.46304V4.40749M4.40724 1.16675H2.4628C1.74688 1.16675 1.1665 1.74712 1.1665 2.46304V4.40749M4.40724 12.8334H2.4628C1.74688 12.8334 1.1665 12.253 1.1665 11.5371V9.59267M9.59243 12.8334H11.5369C12.2528 12.8334 12.8332 12.253 12.8332 11.5371V9.59267" stroke="#606569" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                </div>
                <div v-if="client.isOpenTasks">
                    <div class="category-item" v-for="project in client.projects" :key="project.id">
                        <div class="category-item__title">
                            <p class="category-item__name clickable-task" @click="openTasks(project)">
                                {{project.title}}
                            </p>
                            <p href="" class="item__quantity" @click="openTasks(project)"> 
                                <span>
                                    {{ project.count }} Tasks
                                </span>
                                <svg v-if="project.isOpenTasks" width="9" height="5" viewBox="0 0 9 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M1 1L4.5 4L8 1" stroke="#606569" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                                <svg v-else width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M1.5 4.75L5 1.25L8.5 4.75" stroke="#606569" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                            </p>
                            <p @click="changeProjectFav(project, true)">
                                <svg v-if="project.isFav == 1" class="category-item__star" width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M6.80871 1.18687C7.1173 0.657058 7.8827 0.657057 8.19129 1.18687L9.94924 4.20508C10.0623 4.39916 10.2517 4.53678 10.4712 4.58432L13.8849 5.32354C14.4842 5.45331 14.7207 6.18125 14.3122 6.63846L11.9849 9.24304C11.8353 9.41052 11.7629 9.6332 11.7855 9.85666L12.1374 13.3317C12.1992 13.9417 11.5799 14.3916 11.0189 14.1444L7.8226 12.7359C7.61707 12.6453 7.38293 12.6453 7.1774 12.7359L3.98114 14.1444C3.42007 14.3916 2.80084 13.9417 2.8626 13.3317L3.21445 9.85666C3.23708 9.6332 3.16473 9.41052 3.01508 9.24304L0.687827 6.63846C0.279304 6.18125 0.515825 5.45331 1.11507 5.32354L4.52879 4.58432C4.7483 4.53678 4.93772 4.39916 5.05076 4.20508L6.80871 1.18687Z" fill="#FFD351"/>
                                </svg>
                                <svg v-else class="category-item__empty-star" width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg" >
                                    <path d="M6.73383 11.9479L7.09675 12.7714L6.73383 11.9479L3.93243 13.1823C3.90861 13.1928 3.89285 13.1931 3.88141 13.1917C3.8673 13.19 3.85009 13.1839 3.83333 13.1717C3.81657 13.1596 3.8055 13.1451 3.79947 13.1322C3.79458 13.1217 3.78999 13.1067 3.79262 13.0808L4.101 10.035C4.15473 9.50429 3.9829 8.97543 3.62748 8.57766L1.58774 6.29485C1.5704 6.27544 1.56524 6.26054 1.56306 6.24922C1.56037 6.23525 1.5608 6.21702 1.5672 6.19732C1.5736 6.17762 1.58397 6.16261 1.59436 6.15289C1.60278 6.14501 1.6157 6.13599 1.64114 6.13048L4.63313 5.48258C5.15447 5.36969 5.60435 5.04283 5.87282 4.58189L7.41359 1.93656C7.42669 1.91407 7.43926 1.90456 7.44935 1.89899C7.46181 1.89211 7.47929 1.88689 7.5 1.88689C7.52071 1.88689 7.53819 1.89211 7.55065 1.89899C7.56074 1.90456 7.57331 1.91406 7.58641 1.93656L9.12718 4.58189C9.39565 5.04283 9.84553 5.36969 10.3669 5.48258L13.3589 6.13048C13.3843 6.13599 13.3972 6.14501 13.4056 6.15289C13.416 6.16261 13.4264 6.17762 13.4328 6.19732C13.4392 6.21702 13.4396 6.23525 13.4369 6.24922C13.4348 6.26054 13.4296 6.27544 13.4123 6.29485L11.3725 8.57766C11.0171 8.97543 10.8453 9.50429 10.899 10.035L11.2074 13.0808C11.21 13.1067 11.2054 13.1217 11.2005 13.1322C11.1945 13.1451 11.1834 13.1596 11.1667 13.1717C11.1499 13.1839 11.1327 13.19 11.1186 13.1917C11.1071 13.1931 11.0914 13.1928 11.0676 13.1823L8.26617 11.9479C7.77804 11.7328 7.22196 11.7328 6.73383 11.9479Z" stroke="#FFD351" stroke-width="1.8"/>
                                </svg>
                            </p>
                        </div>
                        <ul class="category-item__list" v-if="project.isOpenTasks">
                            <li v-for="it in project.tasks" :key="it.id" >
                                <span v-if="it.t">
                                    <span class="clickable-task" @click="selectTask(it)">{{it.t}}</span>
                                    <svg v-if="it.tf == 1" class="category-item__star"  @click="changeTaskFav(it)" width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M6.80871 1.18687C7.1173 0.657058 7.8827 0.657057 8.19129 1.18687L9.94924 4.20508C10.0623 4.39916 10.2517 4.53678 10.4712 4.58432L13.8849 5.32354C14.4842 5.45331 14.7207 6.18125 14.3122 6.63846L11.9849 9.24304C11.8353 9.41052 11.7629 9.6332 11.7855 9.85666L12.1374 13.3317C12.1992 13.9417 11.5799 14.3916 11.0189 14.1444L7.8226 12.7359C7.61707 12.6453 7.38293 12.6453 7.1774 12.7359L3.98114 14.1444C3.42007 14.3916 2.80084 13.9417 2.8626 13.3317L3.21445 9.85666C3.23708 9.6332 3.16473 9.41052 3.01508 9.24304L0.687827 6.63846C0.279304 6.18125 0.515825 5.45331 1.11507 5.32354L4.52879 4.58432C4.7483 4.53678 4.93772 4.39916 5.05076 4.20508L6.80871 1.18687Z" fill="#FFD351"/>
                                    </svg>
                                    <svg v-else class="category-item__empty-star"  @click="changeTaskFav(it)" width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg" >
                                        <path d="M6.73383 11.9479L7.09675 12.7714L6.73383 11.9479L3.93243 13.1823C3.90861 13.1928 3.89285 13.1931 3.88141 13.1917C3.8673 13.19 3.85009 13.1839 3.83333 13.1717C3.81657 13.1596 3.8055 13.1451 3.79947 13.1322C3.79458 13.1217 3.78999 13.1067 3.79262 13.0808L4.101 10.035C4.15473 9.50429 3.9829 8.97543 3.62748 8.57766L1.58774 6.29485C1.5704 6.27544 1.56524 6.26054 1.56306 6.24922C1.56037 6.23525 1.5608 6.21702 1.5672 6.19732C1.5736 6.17762 1.58397 6.16261 1.59436 6.15289C1.60278 6.14501 1.6157 6.13599 1.64114 6.13048L4.63313 5.48258C5.15447 5.36969 5.60435 5.04283 5.87282 4.58189L7.41359 1.93656C7.42669 1.91407 7.43926 1.90456 7.44935 1.89899C7.46181 1.89211 7.47929 1.88689 7.5 1.88689C7.52071 1.88689 7.53819 1.89211 7.55065 1.89899C7.56074 1.90456 7.57331 1.91406 7.58641 1.93656L9.12718 4.58189C9.39565 5.04283 9.84553 5.36969 10.3669 5.48258L13.3589 6.13048C13.3843 6.13599 13.3972 6.14501 13.4056 6.15289C13.416 6.16261 13.4264 6.17762 13.4328 6.19732C13.4392 6.21702 13.4396 6.23525 13.4369 6.24922C13.4348 6.26054 13.4296 6.27544 13.4123 6.29485L11.3725 8.57766C11.0171 8.97543 10.8453 9.50429 10.899 10.035L11.2074 13.0808C11.21 13.1067 11.2054 13.1217 11.2005 13.1322C11.1945 13.1451 11.1834 13.1596 11.1667 13.1717C11.1499 13.1839 11.1327 13.19 11.1186 13.1917C11.1071 13.1931 11.0914 13.1928 11.0676 13.1823L8.26617 11.9479C7.77804 11.7328 7.22196 11.7328 6.73383 11.9479Z" stroke="#FFD351" stroke-width="1.8"/>
                                    </svg>
                                </span>
                            </li>
                        </ul>
                        <button class="create-task-btn" v-if="project.isOpenTasks && showAddTask" @click="openCreateTasks(project)">
                            <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M13.7668 8.49998C13.7668 11.6848 11.185 14.2666 8.00016 14.2666V16.0666C12.1791 16.0666 15.5668 12.6789 15.5668 8.49998H13.7668ZM8.00016 14.2666C4.81532 14.2666 2.2335 11.6848 2.2335 8.49998H0.433496C0.433496 12.6789 3.82121 16.0666 8.00016 16.0666V14.2666ZM2.2335 8.49998C2.2335 5.31514 4.81532 2.73331 8.00016 2.73331V0.933313C3.82121 0.933313 0.433496 4.32103 0.433496 8.49998H2.2335ZM8.00016 2.73331C11.185 2.73331 13.7668 5.31514 13.7668 8.49998H15.5668C15.5668 4.32103 12.1791 0.933313 8.00016 0.933313V2.73331Z" fill="#58C7E0"/>
                                <path d="M7.10016 11.1666C7.10016 11.6637 7.50311 12.0666 8.00016 12.0666C8.49722 12.0666 8.90016 11.6637 8.90016 11.1666H7.10016ZM8.90016 5.83331C8.90016 5.33626 8.49722 4.93331 8.00016 4.93331C7.50311 4.93331 7.10016 5.33626 7.10016 5.83331H8.90016ZM10.6668 9.39998C11.1639 9.39998 11.5668 8.99704 11.5668 8.49998C11.5668 8.00292 11.1639 7.59998 10.6668 7.59998V9.39998ZM5.3335 7.59998C4.83644 7.59998 4.4335 8.00292 4.4335 8.49998C4.4335 8.99704 4.83644 9.39998 5.3335 9.39998V7.59998ZM8.90016 11.1666V8.49998H7.10016V11.1666H8.90016ZM8.90016 8.49998V5.83331H7.10016V8.49998H8.90016ZM8.00016 9.39998H10.6668V7.59998H8.00016V9.39998ZM8.00016 7.59998H5.3335V9.39998H8.00016V7.59998Z" fill="#58C7E0"/>
                            </svg>
                            <span> Create new task </span>
                        </button> 
                        <AddTaskModal :key="project.puk" v-if="project.isCreateTasks" @close="project.isCreateTasks = false"/>
                        <div class="bg" :key="project.puk" v-if="project.isCreateTasks" @close="project.isCreateTasks = false"></div>
                    </div>
                </div>
            </div>
        </div>
        <button v-if="showAddProject" class="select-modal__create" @click="$emit('openMenu')">
            <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M13.7668 8.49992C13.7668 11.6848 11.185 14.2666 8.00016 14.2666V16.0666C12.1791 16.0666 15.5668 12.6789 15.5668 8.49992H13.7668ZM8.00016 14.2666C4.81532 14.2666 2.2335 11.6848 2.2335 8.49992H0.433496C0.433496 12.6789 3.82121 16.0666 8.00016 16.0666V14.2666ZM2.2335 8.49992C2.2335 5.31508 4.81532 2.73325 8.00016 2.73325V0.933252C3.82121 0.933252 0.433496 4.32096 0.433496 8.49992H2.2335ZM8.00016 2.73325C11.185 2.73325 13.7668 5.31508 13.7668 8.49992H15.5668C15.5668 4.32096 12.1791 0.933252 8.00016 0.933252V2.73325Z" fill="#58C7E0"/>
                <path d="M7.10016 11.1666C7.10016 11.6636 7.50311 12.0666 8.00016 12.0666C8.49722 12.0666 8.90016 11.6636 8.90016 11.1666H7.10016ZM8.90016 5.83325C8.90016 5.3362 8.49722 4.93325 8.00016 4.93325C7.50311 4.93325 7.10016 5.3362 7.10016 5.83325H8.90016ZM10.6668 9.39992C11.1639 9.39992 11.5668 8.99697 11.5668 8.49992C11.5668 8.00286 11.1639 7.59992 10.6668 7.59992V9.39992ZM5.3335 7.59992C4.83644 7.59992 4.4335 8.00286 4.4335 8.49992C4.4335 8.99697 4.83644 9.39992 5.3335 9.39992V7.59992ZM8.90016 11.1666V8.49992H7.10016V11.1666H8.90016ZM8.90016 8.49992V5.83325H7.10016V8.49992H8.90016ZM8.00016 9.39992H10.6668V7.59992H8.00016V9.39992ZM8.00016 7.59992H5.3335V9.39992H8.00016V7.59992Z" fill="#58C7E0"/>
            </svg>
            <span>
                Create new project
            </span>
        </button>
        <svg class="double-slash" width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13.6667 7.66699L7.66669 13.667" stroke="#BABCBF" stroke-width="2" stroke-linecap="round"/>
            <path d="M13.6667 1.66699L1.66669 13.667" stroke="#BABCBF" stroke-width="2" stroke-linecap="round"/>
        </svg>
    </div>
</template>

<script>
    import vClickOutside from 'v-click-outside'
    import AddTaskModal from '@/components/Modal/AddTaskModal'
    import { useTaskStore } from '@/store/TaskStore'
    import { useWorkspaceStore } from '@/store/WorkspaceStore'
    import { useProjectStore } from '@/store/ProjectStore'

    export default {
        props: ['selectCurrentTask'],
        data(){
            const taskStore = useTaskStore()
            const projectStore = useProjectStore()
            const workspaceStore = useWorkspaceStore()
            return{
                search: '',
                workspaceStore: workspaceStore,
                taskStore: taskStore,
                projectStore: projectStore,
                showFavTask: true,
                showHRDERTask: false,
                showCreateNewModal: false,
                showWorkspace: false,
                showAddProject: false,
                showAddTask: false
            }
        },  
        watch: {
            search(){
                this.taskStore.changeModalSearch(this.search)
                this.$forceUpdate()
            }
        },
        components: {
            AddTaskModal
        },
        directives: {
            clickOutside: vClickOutside.directive
        },
        methods: {
            onClickOutside () {
                this.$emit('close')
            },
            changeWorkplace(item){
                this.workspaces.ws1.isChecked = false;
                this.workspaces.ws2.isChecked = false;
                item.isChecked = true;
            },
            openTasks(item) {
                item.isOpenTasks = !item.isOpenTasks
                this.$forceUpdate()
            },
            openCreateTasks(item) {
                item.isCreateTasks = !item.isCreateTasks
                this.$forceUpdate()
            },
            changeProjectFav(item){
                item.isFav = !item.isFav
                this.taskStore.setAllProjectFav(item.puk, item.isFav)
                this.projectStore.updateFavByUk(item.puk, item.isFav)
            },
            changeTaskFav(item){
                item.tf = item.tf == 1? 0 : 1
                this.taskStore.setAllTaskFav(item.uk, item.tf)
                this.taskStore.fav(item.uk, item.tf)
            },
            selectTask(task){
                this.selectCurrentTask(task)
                this.$emit('close')
            }
        },
        created() {
            this.taskStore.listingAll()
            this.workspaceStore.listing()
        }
    };
</script>

<style scoped>

    p{
        margin: 0;
        padding: 0;
    }
    .clickable-task{
        cursor: pointer;
    }
    .tracker-info .select-modal{
        top: 66px;
        left: 0;
    }

    .select-modal{
        position: absolute;
        width: 480px;
        height: 307px;
        background: #fff;
        border: 2px solid #F6F7F9;
        padding: 15px;
        z-index: 10;
        top: 70px;
        right: 20px;
        box-sizing: border-box;
    }

    .tracker-wrp__item .select-modal{
        right: 0;
        left: 180px;
        top: 50px;
    }

    .add-task__proj .select-modal{
        top: 232px;
        right: 35px;
    }

    input{
        width: 100%;
        height: 42px;
        margin-bottom: 10px;
        border: 1px solid #EAEEF1;
        font-family: 'Montserrat', sans-serif;
        border-radius: 39px;
        padding: 0 20px;
        box-sizing: border-box;
        outline: none;
    }

    .double-slash{
        position: absolute;
        right: 3px;
        bottom: 3px;
        cursor: pointer;
    }

    .item__title{
        display: flex;
        flex-direction: row;
    }

    .item__category{
        color: #BABCBF;
        font-size: 14px;
        font-weight: 600;
        line-height: 17px;
    }

    .select-modal__item{
        border-top: 2px solid #F5F6F8;
        padding: 10px 0;
    }

    .item__quantity{
        margin-left: auto;
        text-decoration: none;
        font-size: 12px;
        font-weight: 600;
        line-height: 15px;
        color: #606569;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        cursor: pointer;
    }

    .item__quantity span{
        padding-right: 5px;
    }

    .item__quater{
        padding-top: 2px;
        margin-left: 15px;
        cursor: pointer;
    }

    .category-item__title{
        margin-top: 10px;
        display: flex;
        flex-direction: row;
    }



    .category-item__name{
        font-size: 14px;
        font-weight: 500;
        line-height: 17px;
        color: #222222;
        padding-left: 18px;
        position: relative;
    }

    .category-item__name:before{
        position: absolute;
        content: "";
        width: 9px;
        height: 9px;
        background-color: #9CEDFF;
        top: 3px;
        left: 0;
        border-radius: 50%;
    }

    .select-modal__wrp{
        height: 205px;
        overflow-y: auto;
    }

    .category-item__empty-star,
    .category-item__star{
        margin-left: 15px;
        padding-top: 2px;
        cursor: pointer;
    }

    .category-item__star{
        fill: #FFD351;
    }

    .category-item__list{
        margin: 0;
        width: 100%;
        box-sizing: border-box;
        background: rgba(156, 237, 255, 0.1);
        padding-left: 0;
        margin-top: 3px;
        font-family: Montserrat;
        font-size: 14px;
        font-weight: 500;
    }

    .category-item__list li{
        list-style: none;
        padding: 4px 0 4px 15px;
        font-size: 14px;
        display: flex;
        flex-direction: row;
        width: 100%;
        box-sizing: border-box;
        justify-content: space-between;
    }

    button{
        border: none;
        background-color: transparent;
        display: flex;
        flex-direction: row;
        align-items: center;
        cursor: pointer;
    }
    
    button span{
        font-family: Montserrat;
        font-size: 14px;
        font-weight: 600;
        line-height: 17px;
        color: #58C7E0;
        padding-left: 5px;
    }

    .category-item__list button{
        margin-left: 5px;
    }

    .category-item__list button span{
        font-size: 12px;
    }

    .select-modal__position{
            top: -380px;
            left: 200px;
            right: 0;
            margin-left: 0;
    }

    .workspace .category-item__name{
        padding-left: 0;
        cursor: pointer;
    }

    .workspace .category-item__name:before{
        width: 0;
        height: 0;
    }

    .workspace-active{
        margin-left: auto;
        font-size: 14px;
        font-weight: 500;
        color: #37BB5C;
    }

    .workspace-active span{
        margin-right: 10px;
    }

    .category-item{
        padding-right: 5px;
    }

    .item__title{
        padding-right: 5px;
    }

    .create-task-btn{
        background: rgba(156, 237, 255, 0.1);
        padding: 5px 15px;
        width: 100%;
        cursor: pointer;
    }


    @media(max-width: 1098px){
        .add-entry__tags .select-modal{
            right: 1.3vw;
            width: 44vw;
        }
    }

    @media (max-width:999px){
        .tracker-wrp__item .select-modal{
            left: 30px;
        }
    }

    @media(max-width:800px) {
        .add-entry__tags .select-modal{
            width: 100%;
            right:0;
        }

        .tracker-wrp__item .select-modal{
            left: 0;
        }
    }

    @media(max-width:650px){
        .tracker-info .select-modal{
            left: 0;
        }
    }

    @media(max-width:615px){
        .tracker-wrp__item .select-modal{
            top: 75px;
        }
    }

    @media(max-width:580px){
        .tracker-info .select-modal{
            width: 100%;
        }

        .tracker-wrp__item .select-modal{
            width: 100%;
        }

        .item__quantity span{
            text-align: end;
        }
    }


</style>