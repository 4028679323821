<template>
        <div class="tag-select" id="tag-select">
            <p class="tag-select__p" @click="showTagsList = !showTagsList">
                <span v-for="item in checkedTags" :key="item"> {{item}} </span>
            </p>
            <div class="tag-select__wrp" v-if="showTagsList" >
                <input class="modal__input" type="checkbox" id="AA" value="tag A" v-model="checkedTags">
                <label class="modal__label" for="AA">tag A</label>
                <input class="modal__input" type="checkbox" id="AB" value="tag B" v-model="checkedTags">
                <label class="modal__label" for="AB">tag B</label>
                <input class="modal__input" type="checkbox" id="AC" value="tag C" v-model="checkedTags">
                <label class="modal__label" for="AC">tag C</label>
                <input class="modal__input" type="checkbox" id="AD" value="tag D" v-model="checkedTags">
                <label class="modal__label" for="AD">tag D</label>
                <input class="modal__input" type="checkbox" id="AE" value="tag E" v-model="checkedTags">
                <label class="modal__label" for="AE">tag E</label>
                <input class="modal__input" type="checkbox" id="AF" value="tag F" v-model="checkedTags">
                <label class="modal__label" for="AF">tag F</label>
                <input class="modal__input" type="checkbox" id="AH" value="tag H" v-model="checkedTags">
                <label class="modal__label" for="AH">tag H</label>
            </div>
        </div>
</template>

<script>
    import vClickOutside from 'v-click-outside'


    export default {
        data() {
            return {
                checkedTags: [],
                showTagsList: false
            }
        },
        directives: {
        clickOutside: vClickOutside.directive
        },
        methods: {
            closeList(){
                this.showTagsList = false
            }
        }
    }
</script>

<style scoped>

    .tag-select{
        background-color: #fff;
        color: #222222;
        box-sizing: border-box;
        right: 0px;
    }

    .tag-select__wrp{
        display: flex;
        flex-direction: column;
        overflow-y: auto;
        max-height: 130px;
        position: absolute;
        width: 100%;
        background-color: #fff;
        z-index: 10;
    }

    .tag-select__p{
        width: 100%;
        margin: 0;
        padding: 6px 15px;
        height: 36px;
        border-radius: 30px;
        border: 2px solid #EAEEF1;
        margin-bottom: 8px;
        box-sizing: border-box;
        overflow-y: auto;
        min-height: 42px;
    }

    .tag-select__p span{
        display: inline-block;
        padding: 1px 5px;
        border: 0.5px solid #eaeaea;
        border-radius: 5px;
        margin: 1px 2px;
    }

    .modal__input{
        display: none;
    }

    .modal__label{
        position: relative;
        padding-left: 30px;
        margin: 5px 0;
        font-size: 14px;
        font-weight: 500;
        line-height: 17px;
    }

    .modal__label:before{
        position: absolute;
        content: "";
        width: 14px;
        height: 14px;
        border: 2px solid #9CEDFF;
        border-radius: 4px;
        top: 0px;
        left: 0;
    }

    .modal__input:checked + .modal__label:before{
        background-image: url('../../assets/img/check.svg');
        background-repeat: no-repeat;
        background-position: center;
    }

    .modal__title{
        font-size: 14px;
        font-weight: 500;
        line-height: 17px;
        padding: 0;
        margin: 0;
        padding-top: 10px;
        color: #BABCBF;
    }

    @media(max-width: 450px) {
        .tag-select{
            width: 70vw;
        }
    }

</style>